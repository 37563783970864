/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

	// Use this variable to set up the common and page specific functions. If you
	// rename this variable, you will also need to rename the namespace below.
	var Sage = {
		// All pages
		'common': {
			init: function() {

				// Select all links with hashes
				$('.sidebar__back-up').click(function() {
					if (location.pathname.replace(/^\//,'') === this.pathname.replace(/^\//,'') && location.hostname === this.hostname) {
					  var target = $(this.hash);
					  target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
					  if (target.length) {
						$('html,body').animate({
						  scrollTop: 0
						}, 1000);
						return false;
					  }
					}
				  });
				setTimeout(function(){
					var popupShown = Cookies.get('newsletterModalShown');
					if(popupShown) {
					} else {
						$('#newsletterModal').modal('show');
					}
				}, 20000);

				// Set cookie when hidden
				$('#newsletterModal').on('hidden.bs.modal', function (e) {
				  	Cookies.set('newsletterModalShown', 'yes', { expires: 31 });
				});

				// Find Youtube iFrames and add a helper class to make them responsive
				$('iframe').each(function() {
					if($(this).attr('src').indexOf('youtube') > -1 ) {
						// We have a Youtube link
						$(this).wrap( "<div class='embed-responsive embed-responsive-16by9'></div>" );
						$(this).addClass('embed-responsive-item');
					}

					if($(this).attr('src').indexOf('facebook') > -1 ) {
						// We have a Facebook link
						$(this).wrap( "<div class='embed-responsive'></div>" );
						$(this).addClass('embed-responsive-item');
						var height = $(this).attr('height');
						var width = $(this).attr('width');
						var percentage = (height / width) * 100;
						$(this).parent().css('padding-bottom', percentage + "%");
					}
				});
			},
			finalize: function() {
				var handle = ally.style.focusWithin();
				if($(window).width() > 960) {
					// Sticky navigation based on this article:
					// https://bootbites.com/articles/freebie-sticky-bootstrap-navbar-scroll-bootstrap-4/
					var stickyToggle = function(sticky, stickyWrapper, scrollElement) {
						var stickyHeight = sticky.outerHeight();
						var stickyTop = sticky.outerHeight() / 2;
						if (scrollElement.scrollTop() >= stickyTop) {
							stickyWrapper.height(stickyHeight);
							sticky.addClass("is-sticky");
						} else {
							sticky.removeClass("is-sticky");
							stickyWrapper.height('auto');
						}
					};

					// Find all data-toggle="sticky-onscroll" elements
					$('[data-toggle="sticky-onscroll"]').each(function() {
						var sticky = $(this);
						var stickyWrapper = $('<div>').addClass('sticky-wrapper'); // insert hidden element to maintain actual top offset on page
						sticky.before(stickyWrapper);
						sticky.addClass('sticky');

						// Scroll & resize events
						$(window).on('scroll.sticky-onscroll resize.sticky-onscroll', function() {
							stickyToggle(sticky, stickyWrapper, $(this));
						});

						// On page load
						stickyToggle(sticky, stickyWrapper, $(window));
					});
				}

			}
		},
		// Home page
		'home': {
			init: function() {
				// JavaScript to be fired on the home page
			},
			finalize: function() {
				// JavaScript to be fired on the home page, after the init JS
			}
		},
		// About us page, note the change from about-us to about_us.
		'about_us': {
			init: function() {
				// JavaScript to be fired on the about us page
			}
		}
	};

	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire: function(func, funcname, args) {
			var fire;
			var namespace = Sage;
			funcname = (funcname === undefined) ? 'init' : funcname;
			fire = func !== '';
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === 'function';

			if (fire) {
				namespace[func][funcname](args);
			}
		},
		loadEvents: function() {
			// Fire common init JS
			UTIL.fire('common');

			// Fire page-specific init JS, and then finalize JS
			$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
				UTIL.fire(classnm);
				UTIL.fire(classnm, 'finalize');
			});

			// Fire common finalize JS
			UTIL.fire('common', 'finalize');
		}
	};

	// Load Events
	$(document).ready(UTIL.loadEvents);

  $(document).ready(function(){
    $("figure").removeAttr("style");
  });

})(jQuery, Cookies); // Fully reference jQuery after this point.
